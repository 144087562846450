@font-face {
  font-family: 'Raleway';
  src: url("../public/Raleway-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html.dark {
  background-color: rgb(15, 23, 42);
}

.color-rosal {
  color: #e20a17;
}
.bg-rosal {
  background-color: #e20a17;
}
.bg-rosal-500 {
  background-color: #a7cf8d;
}
.border-rosal-500 {
  border-color: #a7cf8d;
}

*{
  font-family: 'Raleway';
}
